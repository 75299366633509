<template>
  <v-responsive>
    <v-card v-if="!myVotes || !myVotes.length" class="infos-label pa-0" color="darkgrey" dark raised>
      <v-list-item two-line>
        <v-list-item-content>
          <v-card-title v-if="!myVotes" primary-title class="pa-0">Loading ...</v-card-title>
          <v-card-title v-else primary-title class="pa-0">No votes.</v-card-title>
        </v-list-item-content>
      </v-list-item>
    </v-card>
    <router-link v-for="(vote, index) in myVotes" v-else :key="index" :to="`/rate/${vote.id}`">
      <user-card :user="vote" class="mt-2"></user-card>
    </router-link>
  </v-responsive>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import UserCard from '@/components/UserCard'

export default {
  components: { UserCard },
  computed: {
    ...mapGetters('user', ['getUser']),
    ...mapState('ratings', ['myVotes']),
    ...mapState('app', ['networkOnLine']),
  },
  mounted() {
    this.$store.dispatch('ratings/attachMyVotes')
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.products-page-title {
  text-align: center;
  margin-bottom: 60px;
}

.product-list {
  margin: 20px 0;
}
</style>
