<template>
  <v-responsive>
    <v-card v-if="!ratingUser">
      <v-card-title>Loading ...</v-card-title>
    </v-card>
    <v-card v-else-if="!ratingUser.displayName">
      <v-card-title>User not found!</v-card-title>
    </v-card>
    <v-responsive v-else>
      <user-card v-if="ratingUser" :user="ratingUser"></user-card>
      <v-spacer></v-spacer>
      <v-card v-if="authUser && ratingUser && authUser.id == ratingUser.id" class="pa-0" color="darkgrey" dark>
        <v-card-title>It's not possible to evaluate yourself now.</v-card-title>
      </v-card>
      <available-traits
        v-if="authUser && ratingUser && authUser.id != ratingUser.id"
        :voter="authUser"
        :target="ratingUser"
        :traits="generateTraits(availableTraits, ratingNow)"
      ></available-traits>
    </v-responsive>
  </v-responsive>
</template>
<script>
import { mapState } from 'vuex'
import UserCard from '@/components/UserCard'
import AvailableTraits from '@/components/AvailableTraits'

export default {
  components: { UserCard, AvailableTraits },
  computed: {
    ...mapState('authentication', ['authUser']),
    ...mapState('app', ['networkOnLine']),
    ...mapState('users', ['ratingUser']),
    ...mapState('traits', ['availableTraits']),
    ...mapState('ratings', ['ratingNow']),
    id() {
      return this.$route.params.id
    },
  },
  mounted() {
    this.$store.dispatch('ratings/attachToRating', {
      idtarget: this.$route.params.id,
    })
  },
  created() {
    this.$store.dispatch('users/getUser', this.$route.params.id)
  },
  methods: {
    generateTraits: (avTraits, now) => {
      if (!avTraits) return []
      return avTraits.map(t => {
        return { ...t, value: now[t.id] }
      })
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('ratings/detachFromRating')
    this.$store.dispatch('users/detachFromRating')
    next()
  },
}
</script>
