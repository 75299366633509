import { render, staticRenderFns } from "./TraitItem.vue?vue&type=template&id=92fcdb32&scoped=true&"
import script from "./TraitItem.vue?vue&type=script&lang=js&"
export * from "./TraitItem.vue?vue&type=script&lang=js&"
import style0 from "./TraitItem.vue?vue&type=style&index=0&id=92fcdb32&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92fcdb32",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRating } from 'vuetify/lib/components/VRating';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardActions,VCardTitle,VFadeTransition,VIcon,VListItem,VListItemContent,VRating,VResponsive,VSnackbar,VSpacer})
