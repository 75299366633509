<template>
  <v-responsive>
    <p v-if="traits === null" class="infos-label">Loading...</p>
    <v-card v-if="traits && !traits.length" class="infos-label mt-3 pa-0" color="darkgrey" dark raised>
      <v-list-item two-line>
        <v-list-item-content>
          <v-card-title primary-title class="pa-0"> No ratings. </v-card-title>
        </v-list-item-content>
      </v-list-item>
    </v-card>

    <trait-item
      v-for="(trait, index) in traits"
      :key="trait.id"
      class="trait-row"
      :index="index"
      :disable-actions="!networkOnLine"
      :data="trait"
      :target="target"
      :voter="authUser"
      :value="trait.value"
      :readonly="readonly"
    ></trait-item>
  </v-responsive>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TraitItem from '@/components/TraitItem'

export default {
  components: { TraitItem },
  props: {
    target: Object,
    voter: Object,
    traits: Array,
    readonly: Boolean,
  },
  computed: {
    ...mapState('authentication', ['authUser']),
    ...mapState('app', ['networkOnLine']),
  },
  methods: {
    ...mapActions('traits', ['rate']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.infos-label {
  text-align: center;
}

.trait-row {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px auto;
  justify-content: space-between;
}
</style>
