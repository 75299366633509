<template>
  <v-responsive>
    <v-snackbar v-model="snack" color="success" :timeout="3000"
      >Evaluation link copied!<v-icon>mdi-verified</v-icon></v-snackbar
    >
    <v-card dark raised elevation="12">
      <div class="d-flex flex-no-wrap justify-space-between">
        <div>
          <v-card-title class="headline"
            >{{ user.displayName
            }}<v-icon class="mx-2" @click="copy(getLink(user.id))">mdi-content-copy</v-icon></v-card-title
          >
          <v-card-subtitle v-if="user.id === authUser.id" dense class="d-flex d-sm-none">
            {{ user.id }}
          </v-card-subtitle>
          <v-card-subtitle v-if="user.id === authUser.id" dense class="d-none d-sm-flex">
            {{ getLink(user.id) }}
          </v-card-subtitle>
        </div>
        <v-avatar class="ma-3" size="125" tile>
          <v-img :src="user.photoURL"></v-img>
        </v-avatar>
      </div>
    </v-card>
  </v-responsive>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    user: Object,
  },
  data() {
    return {
      snack: false,
    }
  },
  computed: {
    ...mapGetters('authentication', ['isUserLoggedIn']),
    ...mapState('app', ['networkOnLine']),
    ...mapState('authentication', ['authUser']),
  },
  methods: {
    getLink: id => `${window.location.href.replace(window.location.pathname, '')}/rate/${id}`,
    copy(str) {
      this.snack = true
      const el = document.createElement('textarea')
      el.value = str
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

span.h1 {
  font-size: 1.8em;
}
div.card {
  margin: 10px;
  vertical-align: top;
}
div.inline {
  float: left;
}
.clearBoth {
  clear: both;
}
</style>
