<template>
  <v-responsive>
    <user-card v-if="authUser" :user="authUser"></user-card>
    <v-spacer></v-spacer>
    <available-traits :readonly="true" :traits="convertToTraits(myRatings)"></available-traits>
  </v-responsive>
</template>

<script>
import { mapState } from 'vuex'
import AvailableTraits from '@/components/AvailableTraits'
import UserCard from '@/components/UserCard'

export default {
  components: { AvailableTraits, UserCard },
  computed: {
    ...mapState('app', ['networkOnLine']),
    ...mapState('authentication', ['authUser']),
    ...mapState('ratings', ['myRatings']),
  },
  mounted() {
    this.$store.dispatch('ratings/attachMyRatings')
  },
  methods: {
    getKey: item => {
      return Object.keys(item)[0]
    },
    convertToTraits: ratings => {
      const stats = {}
      const r = []

      if (!ratings) return []
      Object.keys(ratings).map(obk => {
        const item = ratings[obk]
        Object.keys(item).map(i => {
          if (!stats[i]) stats[i] = { count: 0, id: i, value: 0, sum: 0 }
          stats[i].count += 1
          stats[i].sum += item[i]
        })
      })
      Object.keys(stats).map(m => {
        stats[m].value = Math.ceil(stats[m].sum / stats[m].count)
        r.push(stats[m])
      })
      return r
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.products-page-title {
  text-align: center;
  margin-bottom: 60px;
}

.product-list {
  margin: 20px 0;
}
</style>
