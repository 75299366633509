<template>
  <v-responsive v-if="!readonly || data.value">
    <v-snackbar v-model="snack" :timeout="841" color="success" style="display:none">Saved!</v-snackbar>
    <v-card class="pa-0" :color="getCardColor(cardColor)" dark raised>
      <v-list-item two-line>
        <v-list-item-content>
          <v-card-title primary-title class="pa-0">
            {{ getName(availableTraits, data) }}
            <v-spacer></v-spacer>
            <span v-if="data.count">({{ data.count }})</span>
          </v-card-title>
          <v-card-actions class="pa-0">
            <v-spacer></v-spacer>
            <v-fade-transition>
              <v-icon v-if="snack" color="green">mdi-check</v-icon>
            </v-fade-transition>
            <v-rating
              :readonly="readonly"
              :value="data.value"
              :clearable="!readonly"
              background-color="gray"
              dark
              dense
              hover
              size="22"
            >
              <template v-slot:item="props">
                <v-icon
                  hover
                  :color="genColor(props)"
                  @click="
                    save(
                      rate({
                        idrating: data.id,
                        idtarget: (target || {}).id,
                        oldRating: data.value,
                        rating: props.index + 1,
                        oldRating: props.value,
                      })
                    )
                  "
                  v-text="getIcon(props)"
                ></v-icon>
              </template>
            </v-rating>
          </v-card-actions>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-responsive>
</template>

<script>
import { mapActions, mapState } from 'vuex'

const emotes = [
  'mdi-emoticon-tongue',
  'mdi-emoticon-neutral',
  'mdi-emoticon-sad',
  'mdi-emoticon-dead',
  'mdi-emoticon-devil',
]

//const colors = ['yellow lighten-4', 'yellow darken-1', 'amber darken-2', 'orange darken-4', 'deep-orange accent-4']
// const colors = ['yellow lighten-1', 'yellow darken-1', 'yellow darken-2', 'yellow darken-3', 'yellow darken-4']
///const colors = ['yellow lighten-5', 'yellow lighten-2', 'yellow darken-1', 'yellow darken-2', 'yellow darken-4']
//const colors = ['yellow accent-2', 'yellow accent-4', 'amber accent-4', 'orange accent-4', 'red darken-2']
const colors = ['yellow', 'amber', 'orange', 'deep-orange', 'red accent-4']

export default {
  props: {
    data: Object,
    index: Number,
    target: Object,
    voter: Object,
    value: Number,
    readonly: Boolean,
  },
  data() {
    return {
      snack: false,
      cardColor: 'darkGray',
    }
  },
  computed: {
    ...mapState('ratings', ['ratingNow']),
    ...mapState('traits', ['availableTraits']),
  },
  methods: {
    async save(method) {
      await method
      this.snack = true
    },
    ...mapActions('ratings', ['rate', 'unrate']),
    getCardColor: color => {
      return color
    },
    getIcon: prop => {
      return emotes[prop.index]
    },
    genColor: rating => {
      if (!rating || rating.index >= rating.value) return 'grey darken-2'
      return colors[rating.index]
    },
    getName: (traits, data) => {
      if (!traits) return 'LOADING ...'
      const item = traits.find(i => i.id == data.id)
      return item.name
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.product-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>
